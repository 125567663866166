import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
//import  moment from 'moment';
import { Store } from '@ngxs/store';
import {Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { FacturaService } from '../factura.service';
import { GetUltimoPeriodoFacturado } from 'src/app/store/periodo/actions';
import { OperacionSiNoComponent } from 'src/app/shared/operacion-si-no/operacion-si-no.component';
import { OperacionInformacionComponent } from 'src/app/shared/operacion-informacion/operacion-informacion.component';
import  dayjs from 'dayjs'


const  meses=["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]

@Component({
  selector: 'app-proceso-de-facturacion',
  templateUrl: './proceso-de-facturacion.component.html',
  styleUrls: ['./proceso-de-facturacion.component.css']
})
export class ProcesoDeFacturacionComponent implements OnInit,OnDestroy {


  @ViewChild("informacionProcesoFacturacion", { static: true })
  operacionInformacionComponent: OperacionInformacionComponent;

  @ViewChild(OperacionSiNoComponent, { static: true })
  operacionSiNoComponent: OperacionSiNoComponent;

  mensajeInformacion:""

  anioUltimoPeriodoFacturado:number
  mesUltimoPeriodoFacturado:number
  periodoSubscription: Subscription

  periodosFacturacionForm: UntypedFormGroup

  fechaActual=  dayjs().format("DD/MM/YYYY");

totalPeriodosAFacturas = 2

valorProgresoFacturacion = 0

estaProcesandoFacturacion=false
esFacturacionExitosa=false
totalFacturado = 0
estaProcesandoGuard=false

  constructor(private store:Store,private fb:UntypedFormBuilder, private fs:FacturaService) {
    
    this.periodosFacturacionForm =  this.fb.group({
      habilitadoFacturarServicioMedido:[true],
      periodosFacturacion:this.fb.array([])
    })
  }


  ngOnInit(): void {
    //obtengo los periodos para determinar cuales deben ser facturados
    this.store.dispatch(new GetUltimoPeriodoFacturado())

    this.periodoSubscription = this.store.select(state => state.periodo.ultimo_periodo_facturado).pipe(
      filter(datos=>!!datos)
    ).subscribe(
      periodosR=> {

        this.anioUltimoPeriodoFacturado = +periodosR.substr(0,4)
        this.mesUltimoPeriodoFacturado = +periodosR.substr(-2)
        this.agregarPeriodoFacturacion(1)
        this.agregarPeriodoFacturacion(2)

      }
    );
  }

  get habilitadoFacturarServicioMedidoField(){
    return this.periodosFacturacionForm.get("habilitadoFacturarServicioMedido")
  }

  get periodosFacturacionField() : UntypedFormArray {
    return this.periodosFacturacionForm.get("periodosFacturacion") as UntypedFormArray
  }




 agregarPeriodoFacturacion(incrementoParaNuevoPeriodo){

  this.periodosFacturacionField.push(this.nuevoPeriodoAFacturar(incrementoParaNuevoPeriodo));

 }


 nuevoPeriodoAFacturar(incrementoParaNuevoPeriodo): UntypedFormGroup{

  let mesPeriodo
  let anioPeriodo


  if( this.mesUltimoPeriodoFacturado + incrementoParaNuevoPeriodo > 12 ){
    mesPeriodo = ( this.mesUltimoPeriodoFacturado +incrementoParaNuevoPeriodo) % 12
    anioPeriodo = this.anioUltimoPeriodoFacturado + 1
  }else{
    mesPeriodo= this.mesUltimoPeriodoFacturado + incrementoParaNuevoPeriodo
    anioPeriodo = this.anioUltimoPeriodoFacturado
  }

  let idPeriodo = `${anioPeriodo}${mesPeriodo.toString().padStart(2,0)}`
  let periodo = `${meses[mesPeriodo-1]} ${anioPeriodo}`

  let vencimientos = this.getFechasVencimiento(mesPeriodo,anioPeriodo)

  return this.fb.group({
    idPeriodo,
    periodo,
    fechaPrimerVencimiento: [vencimientos.fechaPrimerVencimiento,Validators.required],
    fechaSegundoVencimiento: [vencimientos.fechaSegundoVencimiento,Validators.required]
  })

 }

 getFechasVencimiento(mes,anio){
// determinamos la primera y segunda fecha de vencimiento
// el mes de vencimiento es el posterior al mes del periodo en cuestion.
// el dia de vencimiento es 12 y 19 segun corresponda
// el usuario puede modificar dichas fechas

  let fechaPrimerVencimiento = dayjs(`${anio}-${mes}-12`).add(1,'months').format("YYYY-MM-DD")
  let fechaSegundoVencimiento = dayjs(`${anio}-${mes}-19`).add(1,'months').format("YYYY-MM-DD")

  return {fechaPrimerVencimiento,fechaSegundoVencimiento}
 }

 quitarPeriodoFacturacion(){
  this.periodosFacturacionField.removeAt(this.periodosFacturacionField.length-1)
 }


 incrementarDecrementarPeriodosFacturacion(valor){
    if(valor<0 && this.totalPeriodosAFacturas>1 ){
      this.quitarPeriodoFacturacion()
      this.totalPeriodosAFacturas-=1
    }

    if(valor>0 && this.totalPeriodosAFacturas<12){
      this.totalPeriodosAFacturas+=1
      this.agregarPeriodoFacturacion(this.totalPeriodosAFacturas)
    }

    }



onSubmit(e:Event){
  event.preventDefault();
  if (this.periodosFacturacionForm.valid) {
   this.operacionSiNoComponent.open()
  }

}

retornoOperacionSiNo(respuesta){

    if (respuesta) {
       // indicamos que comenzo el proceso de facturacion
    this.estaProcesandoFacturacion = true
    this.estaProcesandoGuard = true
    this.valorProgresoFacturacion = 0
    // seteamos un intervalo el cual cada 4 segundo incrementa la barra de progreso
    // lo hacemos para que el usuario note que se esta realizando la operacion
    let timerId = setInterval(() => {
      if(this.valorProgresoFacturacion===100){
        this.valorProgresoFacturacion=50
      }
      this.valorProgresoFacturacion+=1

    }, 8000);

    // generamos el objeto a enviar y desabilitamos los elementos para que no puedan ser modificados

    let periodosParaFacturar = []

    this.deshabilitarDatos()

    periodosParaFacturar = this.periodosFacturacionField.controls.map(periodo=> {
      return {"ID_Periodo": periodo.get('idPeriodo').value,
    "FechaPrimerVenc":periodo.get('fechaPrimerVencimiento').value,
    "FechaSegundoVenc": periodo.get('fechaSegundoVencimiento').value}
    })


    this.fs.generarFacturacion(periodosParaFacturar,this.habilitadoFacturarServicioMedidoField.value).subscribe(
      (facturacionReturn => {
        clearInterval(timerId)
        if(facturacionReturn.success){
          this.valorProgresoFacturacion = 100
          this.esFacturacionExitosa = true
          this.totalFacturado = facturacionReturn.total_facturado
          this.estaProcesandoGuard = false
        }else{
          this.mensajeInformacion = facturacionReturn.message
          this.operacionInformacionComponent.open();
          this.habilitarDatos()
        }

      } ),
      (error=>{
        this.habilitarDatos()
      })
    )
    }
}

habilitarDatos(){
  this.valorProgresoFacturacion = 100
  this.estaProcesandoFacturacion = false
  this.estaProcesandoGuard = false
  this.periodosFacturacionField.controls.forEach(periodo=> {
    periodo.get('fechaPrimerVencimiento').enable()
    periodo.get('fechaSegundoVencimiento').enable()
  })
}

deshabilitarDatos(){

  this.periodosFacturacionField.controls.forEach(periodo=> {
    periodo.get('fechaPrimerVencimiento').disable()
    periodo.get('fechaSegundoVencimiento').disable()
  })
}

  ngOnDestroy(){
    this.periodosFacturacionForm.reset()
    this.periodoSubscription.unsubscribe()
  }


  onExit(){

    if (this.estaProcesandoGuard){
     alert('No podrá salir hasta que el proceso de facturación finalice!')
    return false;
    }

    return true;
  }



}
