import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AgButtonRenderComponent } from 'src/app/shared/ag-button-render/ag-button-render.component';
import { Time } from 'src/app/utils/time';
import { FormularioControlSituacionEspecialComponent } from './formulario-control-situacion-especial/formulario-control-situacion-especial.component';
import Swal from 'sweetalert2'
import { SocioNavigationService } from '../socio-navigation.service';
import { BeneficiosService } from 'src/app/beneficios/beneficios.service';
import { ControlSituacionEspecialOM2312Socio } from 'src/app/beneficios/beneficios.model';




@Component({
  selector: 'app-socio-control-situacion-especial',
  templateUrl: './socio-control-situacion-especial.component.html',
  styleUrls: ['./socio-control-situacion-especial.component.css']
})
export class SocioControlSituacionEspecialComponent implements OnInit {

  @ViewChild('agGridControlSituacionEspecial', { static: true }) agGridControlSituacionEspecial: any;

  @ViewChild(FormularioControlSituacionEspecialComponent, { static: true })
  formularioComponent: FormularioControlSituacionEspecialComponent;

  rowData$: Observable<ControlSituacionEspecialOM2312Socio[]>

  socioSituacionEspecialSelect:ControlSituacionEspecialOM2312Socio


  columnDefs = [
    {headerName: 'IDSocio', field: 'id_socio',sortable: true, filter: true, hide:true },
    {headerName: 'Código', field: 'codsocio',sortable: true, filter: true, width:100 },
    {headerName: 'SubCod', field: 'subcodsocio',sortable: true, filter: true, width:100 },
    {headerName: 'Nombre', field: 'nombre',sortable: true, filter: true,resizable:true },
    {headerName: 'Barrio', field: 'barrio',sortable: true, filter: true,resizable:true },
    {headerName: 'Situacion Especial', field:'situacion',sortable: true, filter: true },
    {headerName: 'Fecha Inicio', field: 'fechainicio',sortable: true, filter: true, width:130,
    valueFormatter: function(params){
      if (params.value!==''){
        return  Time.formatDate(params.value,"DD/MM/YYYY")
      }else{
        return null
      }
    } },
    {headerName: 'Fecha Fin', field:'fechafin',sortable: true, filter: true, width:130,
    valueFormatter: function(params){
      if (params.value!==''){
        return  Time.formatDate(params.value,"DD/MM/YYYY")
      }else{
        return null
      }
    } },
    {headerName: 'Acción a realizar', field:'accion_a_realizar',         sortable: true, filter: true },    
    {headerName: 'Socio Estado', field:'socio_estado',         sortable: true, filter: true },
    {headerName: 'Tarifa Base', field:'tarifa',sortable: true, filter: true },
    { pinned: 'right' ,
    cellRenderer: 'buttonRenderer',
    editable:false,
    width: 67,
    cellRendererParams: {
      onClick: this.onEditarSituacion.bind(this),
      label: 'editar situacion',
      classBtn: 'edit'
    }  },
    { pinned: 'right' ,
    cellRenderer: 'buttonRenderer',
    editable:false,
    width: 70,
    cellRendererParams: {
      onClick: this.onVerSocio.bind(this),
      label: 'ver socio',
      classBtn: 'primary'
    }  }

  ];

  frameworkComponents: any;

  constructor(private sb:BeneficiosService, private sns:SocioNavigationService) {
    this.frameworkComponents = {
      buttonRenderer: AgButtonRenderComponent,
    }
  }

  ngOnInit(): void {
    this.actualizarListaSociosConSituacionEspecialConVencimiento()
  }

  onEditarSituacion(row){
        this.socioSituacionEspecialSelect = row.rowData
    this.formularioComponent.open()

  }

  onVerSocio(row){
    this.socioSituacionEspecialSelect = row.rowData
    this.sns.goToSocio(this.socioSituacionEspecialSelect.codsocio,this.socioSituacionEspecialSelect.subcodsocio)
  }

  onQuitarSituacionEspecial(socioEvent){
    this.sb.quitarASocioSituacionEspecialConVencimiento(socioEvent).subscribe(
      ()=>{

          Swal.fire(
            '',
            'El socio ha sido actualizado de manera exitosa! La lista será actualizada',
            'success'
          ).then(
            ()=>{
              this.actualizarListaSociosConSituacionEspecialConVencimiento()
            }
          )

         }
    )
  }

  actualizarListaSociosConSituacionEspecialConVencimiento(){
    this.rowData$ = this.sb.getSociosConSituacionEspecialConVencimiento()
  }


  onBtExport(){
    this.agGridControlSituacionEspecial.api.exportDataAsCsv({ fileName: 'listado-control-situacion-especial',columnSeparator:';' })
   }

   clearFilter(){
    this.agGridControlSituacionEspecial.api.setFilterModel(null);
  }


}
