<div class="card">
  <div class="card-header" style="background-color:#17a2b8; color: white">Proceso de Facturación</div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-info" role="alert" style="font-size: 14px;">
          <div class="row">
            <div class="col-6 mt-2">
              Recuerde que deberá chequear:
              <ul>
                <li>La existencia del CESP habilitado para su uso <small>no vencido</small> &nbsp; <a  routerLink="/afip/cesp" style="color:#0056b3" >Ir a CESP</a></li>
                <li>La carga de los estados para los usuarios de media</li>
                <li>Las fechas de vencimiento para cada periodo</li>
              </ul>
            </div>
            <div class="col-6 mt-2">
              Durante el proceso de facturación se realizarán las siguiente operaciones, según correspondan:
              <ul>
                <li>Se Impactarán los Acuerdo / OM 2312 <small>setea el importe según valor de situacion especial </small> </li>
                <li>Se realizará el Cobro del Medidor <small>aplica a usuarios de media</small></li>
                <li>Se impactará el pago de facturas por abono de recibo semestral</li>
                <li>Se Impactarán los créditos otorgados</li>
              </ul>
            </div>
          </div>


        </div>
      </div>

    </div>
    <form [formGroup]="periodosFacturacionForm" novalidate (ngSubmit)="onSubmit($event)">
    <div class="row mt-3">

      
      <div class="col-md-4">


        <fieldset class="p-2" style="border: solid 1px #ddd">
          <legend style="font-size: 13px"><span class="badge badge-pill badge-info">1</span> Datos de facturación ...
          </legend>
          <div class="form-group row">
            <label for="fechaFacturacionInput" class="col-sm-6 col-form-label-sm col-form-label">Fecha de
              Facturación</label>
            <div class="col-sm-6">
              <input type="text" class="form-control-plaintext form-control-sm" id="fechaFacturacionInput"
                [value]="fechaActual" readonly>
            </div>

          </div>

          <div class="form-group row">
            <label for="totalPeriodosInput" class="col-sm-8 col-form-label-sm col-form-label">Cantidad de periodos a
              facturar: {{totalPeriodosAFacturas}}</label>
            <div class="col-sm-4">
              <!--     <input type="number" min="1" max="12" class="form-control form-control-sm" value=2 id="totalPeriodosInput"
                (change)="onTotalPeriodosChange($event)" #totalPeriodosInput> -->
              <div class="btn-group mr-2" role="group">
                <button type="button" class="btn btn-outline-info btn-sm"
                  (click)="incrementarDecrementarPeriodosFacturacion(-1)"
                  [disabled]="this.estaProcesandoFacturacion">-</button>
                <button type="button" class="btn btn-outline-info btn-sm"
                  (click)="incrementarDecrementarPeriodosFacturacion(1)"
                  [disabled]="this.estaProcesandoFacturacion">+</button>
              </div>
            </div>

          </div>


          <div class="form-group row">
            <label for="inputFacturarServicioMedido" class="col-sm-6 col-form-label col-form-label-sm ">Facturar Servicio Medido</label>
            <div class="col-sm-2">
              <input type="checkbox" class="form-control form-control-sm" id="inputFacturarServicioMedido" formControlName="habilitadoFacturarServicioMedido" placeholder="" >
            </div>
          </div>

        </fieldset>
      </div>

      <div class="col-md-8">
        <fieldset class="p-2" style="border: solid 1px #ddd">
          <legend style="font-size: 13px"><span class="badge badge-pill badge-info">2</span> Periodos a Facturar...
          </legend>


         
            <div class="row">


              <ng-container formArrayName="periodosFacturacion">
              <ng-container *ngFor="let perido of periodosFacturacionField.controls; let i=index">

                <div class="col-md-6 mt-2">
                  <div class="card">
                    <div class="card-body">
                      <div [formGroupName]="i">
                        <h5 class="card-title">Período
                          {{perido.get('idPeriodo').value.substr(-2)}}/{{perido.get('idPeriodo').value.substr(0,4)}}
                        </h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{perido.get('periodo').value}}</h6>

                        <div class="form-group row">
                          <label [attr.for]="'fechaPrimerVencimientoInput'+i"
                            class="col-sm-6 col-form-label col-form-label-sm">Primer Vencimiento</label>

                          <div class="col-sm-6">
                            <input [attr.id]="'fechaPrimerVencimientoInput'+i" type="date"
                              class="form-control form-control-sm" formControlName="fechaPrimerVencimiento">
                            <div
                              *ngIf="perido.get('fechaPrimerVencimiento').errors && perido.get('fechaPrimerVencimiento').touched"
                              class="col-sm-4 invalid-feedback">
                              valor requerido!
                            </div>
                          </div>

                        </div>
                        <div class="form-group row">
                          <label [attr.for]="'fechaSegundoVencimientoInput'+i"
                            class="col-sm-6 col-form-label col-form-label-sm">Segundo Vencimiento</label>

                          <div class="col-sm-6">
                            <input [attr.id]="'fechaSegundoVencimientoInput'+i" type="date"
                              class="form-control form-control-sm" formControlName="fechaSegundoVencimiento">
                            <div
                              *ngIf="perido.get('fechaSegundoVencimiento').errors && perido.get('fechaSegundoVencimiento').touched"
                              class="col-sm-4 invalid-feedback">
                              valor requerido!
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


              </ng-container>
            </ng-container>


            </div>
            <div class="row mt-4">
              <div class="col-md-12" *ngIf="!estaProcesandoFacturacion;else procesandoFacturacion">
                <div class="float-right">
                  <button class="btn btn-success btn-sm" type="submit"
                    [disabled]="periodosFacturacionForm.invalid">Generar Facturación</button>
                </div>
              </div>
            </div>
        

          <div class="alert alert-success" role="alert" *ngIf="esFacturacionExitosa">
            La facturación ha sido generada exitosamente! Se generaron un total de {{totalFacturado}} facturas.
          </div>

          <ng-template #procesandoFacturacion>
            <div class="col-md-12">
              <p class="text-center" *ngIf="!esFacturacionExitosa">Aguarde un instante. La operación puede demorar
                varios minutos!</p>
           
              <div class="progress" >
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [attr.aria-valuenow]="valorProgresoFacturacion" aria-valuemin="0" aria-valuemax="100" 
                [style.width]=" valorProgresoFacturacion.toString() + '%'"></div>
              </div>
            </div>
          </ng-template>





        </fieldset>

      </div>

     
    </div>
  </form>
  </div>

  <app-operacion-si-no [titulo]="'Confirmar facturación'"
    [contenidoMensaje]="'Está Ud. realmente seguro de querer comenzar el proceso de facturación ? Este proceso no puede ser cancelado y al facturarse más de un período sólo puede anularse la facturación del último período pero no de los períodos anteriores'"
    (retornoOperacion)="retornoOperacionSiNo($event)" [id]="'siNoNuevaFacturacion'"></app-operacion-si-no>

    <app-operacion-informacion [titulo]="'Información insuficiente'" [contenidoMensaje]="mensajeInformacion"
   #informacionProcesoFacturacion [id]="'informacionProcesoFacturacion'">
</app-operacion-informacion>
