<div class="card">
  <div class="card-header" style="background-color:#17a2b8; color: white">Proceso de Facturación Mensual</div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-info" role="alert" style="font-size: 14px;">
          <div class="row">
            <div class="col-12">
              El proceso de facturación mensual solo se aplicará en aquellos socios/usuarios habilitados para la facturación mensual
            </div>

          </div>
          <div class="row">
            <div class="col-6 mt-2">
              Recuerde que deberá chequear:
              <ul>
                <li>La existencia del CESP habilitado para su uso <small>no vencido</small> &nbsp; <a  routerLink="/afip/cesp" style="color:#0056b3" >Ir a CESP</a></li>
                <li>La carga de los estados para los socios/usuarios de media habilitados para facturación mensual</li>
              </ul>
            </div>
            <div class="col-6 mt-2">
              Durante el proceso de facturación se realizarán las siguiente operaciones, según correspondan:
              <ul>
                <li>Se Impactarán los Acuerdo / OM 2312 <small>setea el importe total a 220 </small> </li>
                <li>Se realizará el Cobro del Medidor <small>aplica a usuarios de media</small></li>
                <li>Se impactará el pago de facturas por abono de recibo semestral</li>
                <li>Se Impactarán los créditos otorgados</li>
              </ul>
            </div>
          </div>


        </div>
      </div>

    </div>

    <div class="row mt-3">

    <div class="col-md-12">
<form [formGroup]="facturacionMensualForm" (ngSubmit)="onSubmit($event)"  novalidate>
  <div class="row">
    <div class="col-md-5">


      <fieldset class="p-2" style="border: solid 1px #ddd">
        <legend style="font-size: 13px"><span class="badge badge-pill badge-info">1</span> Seleccione el periodo de facturación ... <small>solo se incluyen periodos no facturados</small>
        </legend>

        <div class="row" >
          <div class="col-md-12">
            <select class="form-control form-control-sm" id="selectPeriodoFacturacion" formControlName="periodoFacturacion"
            [ngClass]="{ 'is-invalid': periodoFacturacionIsInvalid  }">
              <option *ngFor="let periodo of periodosFacturacion$|async" [value]="periodo.ID_Periodo" >Periodo: {{ periodo.ID_Periodo}} - vencimiento1: {{ periodo.FechaPrimerVenc| date:'dd/MM/yyyy'}} - vencimiento2: {{ periodo.FechaSegundoVenc| date:'dd/MM/yyyy'}}</option>
            </select>

            <div *ngIf="periodoFacturacionIsInvalid" class="invalid-feedback">
              <small>Valor requerido</small>
            </div>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="col-md-7">
      <fieldset class="p-2" style="border: solid 1px #ddd">
        <legend style="font-size: 13px"><span class="badge badge-pill badge-info">2</span> Ingrese la fecha de facturación...
        </legend>

        <div class="form-group ">
          <div class="col-4">
            <input class="form-control form-control-sm" formControlName="fechaFacturacion"  type="date" [ngClass]="{ 'is-invalid': fechaFacturacionIsInvalid}">
          </div>
          <div *ngIf="fechaFacturacionIsInvalid" class="invalid-feedback col-3">
            <small>Valor requerido</small>
          </div>
        </div>

      </fieldset>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-12" *ngIf="!estaProcesandoFacturacion;else procesandoFacturacion">
      <div class="float-right">
        <button class="btn btn-success btn-sm" type="submit"
          [disabled]="facturacionMensualForm.invalid">Generar Facturación</button>
      </div>
    </div>
  </div>


      </form>

      <div class="alert alert-success" role="alert" *ngIf="esFacturacionExitosa">
        La facturación ha sido generada exitosamente! Se generaron un total de {{totalFacturado}} facturas.
      </div>

      <ng-template #procesandoFacturacion>
        <div class="col-md-12">
          <p class="text-center" *ngIf="!esFacturacionExitosa">Aguarde un instante. La operación puede demorar
            varios minutos!</p>
         

          <div class="progress" >
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [attr.aria-valuenow]="valorProgresoFacturacion" aria-valuemin="0" aria-valuemax="100" 
            [style.width]=" valorProgresoFacturacion.toString() + '%'"></div>
          </div>
        </div>
      </ng-template>
    </div>



    </div>
  </div>
</div>

<app-operacion-si-no [titulo]="'Confirmar facturación'"
[contenidoMensaje]="'Está Ud. realmente seguro de querer comenzar el proceso de facturación ? Este proceso no puede ser cancelado...'"
(retornoOperacion)="retornoOperacionSiNo($event)" [id]="'siNoNuevaFacturacionMensual'"></app-operacion-si-no>

<app-operacion-informacion [titulo]="'Información insuficiente'" [contenidoMensaje]="mensajeInformacion"
#informacionProcesoFacturacionMensual [id]="'informacionProcesoFacturacionMensual'">
</app-operacion-informacion>



